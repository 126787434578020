import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineMenuAlt2, HiOutlineX } from "react-icons/hi";

import header from '../assets/images/header.jpg';
import header_en from '../assets/images/header_en.jpg';

import br from '../assets/images/br.png';
import en from '../assets/images/en.png';

import LanguageContext from '../contexts/language';
import AuthContext from '../contexts/auth';

export default function Header() {

    const { signed } = useContext(AuthContext);
    const { language, setLanguageStore } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    function setLanguage(lang) {
        setLanguageStore(lang);
    }

    const [menu, setMenu] = useState('close');

    useEffect(() => {
        document.title = language.site_name;
    }, [language]);

    return (
        <div className="header-menu">
            <div className="header">
                <Link to="/">
                    <img src={lang==='pt' ? header : header_en} className="bg-cover" />
                </Link>
            </div>
            <Navbar>
                <Container className="flex-row">
                    <div className="menu-mobile" onClick={() => setMenu('open')}>
                        <HiOutlineMenuAlt2 />
                        <NavLink to="/assistir" className="nav-link nav-login">{language.menu_watch}</NavLink>
                    </div>
                    <div className="menu-content-desktop">
                        <Navbar.Collapse>
                            <Nav>
                                <NavLink to="/home" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_home}</NavLink>
                                <NavLink to="/programacao" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_agenda}</NavLink>
                                <NavLink to="/painelistas" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_news}</NavLink>
                                <NavLink to="/semana-da-filantropia" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_week}</NavLink>
                                <NavLink to="/imprensa" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_imprensa}</NavLink>
                                <NavLink to="/registre-se" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_inscricao}</NavLink>
                                <NavLink to="/hotel" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_hotel}</NavLink>
                                <NavLink to="/recomendacoes" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_recommendation}</NavLink>
                                <NavLink to="/assistir" className="nav-link nav-login ms-2">{language.menu_watch}</NavLink>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                    <NavDropdown title={<>
                            <img src={lang==='pt' ? br : en} />
                            <span>{language.language_active }</span>
                        </>} menuVariant="dark">
                        <NavDropdown.Item onClick={() => {setLanguage('pt')}}>{language.language_pt_inverse}</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {setLanguage('en')}}>{language.language_en_inverse}</NavDropdown.Item>
                    </NavDropdown>
                </Container>
            </Navbar>
            <div className={`menu-content ${menu}`}>
                <div className="menu-mobile-close" onClick={() => setMenu('close')}>
                    <HiOutlineX />
                </div>
                <Navbar>
                    <Nav>
                        <NavLink to="/home" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_home}</NavLink>
                        <NavLink to="/programacao" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_agenda}</NavLink>
                        <NavLink to="/painelistas" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_news}</NavLink>
                        <NavLink to="/semana-da-filantropia" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_week}</NavLink>
                        <NavLink to="/imprensa" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_imprensa}</NavLink>
                        <NavLink to="/registre-se" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_inscricao}</NavLink>
                        <NavLink to="/hotel" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_hotel}</NavLink>
                        <NavLink to="/recomendacoes" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>{language.menu_recommendation}</NavLink>
                        <NavLink to="/assistir" className="nav-link nav-login">{language.menu_watch}</NavLink>
                    </Nav>
                </Navbar>
            </div>
        </div>
    );
}