import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import LanguageContext from '../../contexts/language';

export default function Home() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');


    return (
        <div className="page">
            <Header />
            <div className="mt-40 mb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 m-auto">
                            {/* {lang==='pt' ?
                            <div className="alert-text">
                                <p>asasasasa</p>
                            </div>:
                            <div className="alert-text">
                                <p>asasasasa</p>
                            </div>} */}
                            {lang==='pt' ?
                            <div className="text">
                                <p>Bem-vindo ao Climate Solutions Forum (CSF), promovido pela plataforma F20 (Foundations 20)!</p>
                                <p>Convidamos você a mergulhar em discussões significativas sobre questões prioritárias da presidência do G-20, como mudanças climáticas, combate às desigualdades e promoção da resiliência. Em colaboração com o Instituto Clima e Sociedade, a Fundação Avina e o GIFE, estamos entusiasmados em reunir um grupo diversivo que inclui fundações, filantropos, gestores públicos, pesquisadores, empresas e sociedade civil de países do G-20 para explorar soluções para o desenvolvimento sustentável.</p>
                                <p>Este ano, nosso tema "Accountability in Action: Building bridges for North-South partnerships" prepara o cenário para a formação de novas alianças, compartilhamento de conhecimento e ampliação da voz da filantropia do Sul global nas discussões do G20.</p>
                                <p>O evento de dois dias contará com uma programação de palestras, painéis, eventos paralelos e oportunidades de networking projetadas para inspirar ação e colaboração.</p>
                                <p>Além do próprio evento, vislumbramos uma semana de networking e trocas frutíferas no espírito da filantropia.</p>
                                <p>Junte-se a nós nesta jornada em direção a um futuro mais sustentável e resiliente para todos. Pronto para fazer a diferença? Registre-se agora para garantir seu lugar!</p>
                            </div>:
                            <div className="text">
                                <p>Welcome to the Climate Solutions Forum (CSF) hosted by the F20 (Foundations 20) platform!</p>
                                <p>We invite you to dive into meaningful discussions on key priority issues of the G-20 presidency such as climate change, tackling inequalities, and promoting resilience. In collaboration with the Institute for Climate and Society, the Avina Foundation, and GIFE, we're thrilled to bring together a diverse group including foundations, philanthropists, public managers, researchers, businesses, and civil society from across G-20 countries to explore solutions for sustainable development.</p>
                                <p>This year, our theme "Accountability in Action: Building bridges for North-South partnerships" sets the stage for forging new alliances, sharing knowledge, and amplifying the voice of global philanthropy from the South in G20 discussions.</p>
                                <p>The two-day event will feature an engaging lineup of talks, panels, side events, and networking opportunities designed to inspire action and collaboration.</p>
                                <p>Beyond the event itself, we envision a week of networking and fruitful exchanges in the spirit of philanthropy.</p>
                                <p>Join us on this journey towards a more sustainable and resilient future for all. Ready to make a difference? Register now to secure your spot!</p>
                            </div>}
                            <div className="d-btn mt-30 mobile-view">
                                <Link to="registre-se" className="btn-send text-uppercase">{language.menu_inscricao}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


