import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import LanguageContext from '../../contexts/language';
import hotel from '../../assets/images/hotel.jpg';

export default function Hotel() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');


    return (
        <div className="page">
            <Header />
            <div className="full mt-80 mb-40 form">
                <h3 className="title_b">
                    {language.menu_hotel}
                </h3>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-5">
                            <img src={hotel} className="bg-cover thumb" />
                        </div>
                        <div className="col-md-7">
                            {lang==='pt' ?
                            <div className="text">
                                <p>O Hotel Prodigy Santos Dumont será o local do nosso evento! </p>
                                <p>Além de servir como espaço para os dois dias de evento aberto, ele também será o hotel oficial do evento. Com a conveniência de estar integrado ao Aeroporto Santos Dumont, possui ainda, uma localização privilegiada e de fácil acesso, bem no centro financeiro da capital carioca. Os participantes do evento possuem desconto na diária, basta utilizar o código <strong>ICS10</strong></p>
                            </div>:
                            <div className="text">
                                <p>The Prodigy Santos Dumont Hotel will be the venue for our event!</p>
                                <p>As well as serving as the location for the two open days, it will be the official event hotel. With the convenience of being integrated into the Santos Dumont Airport, it also offers a prime location with easy access, right in the financial heart of Rio de Janeiro. Event participants can enjoy a discount on their daily rate by using the code <strong>ICS10</strong>.</p>
                            </div>}
                            <div className="d-btn mt-30">
                                <a href={lang==='pt' ? 'https://www.prodigyhotels.com.br/prodigy-hotel-santos-dumont-airport' : 'https://www.prodigyhotels.com.br/en/prodigy-hotel-santos-dumont-airport'} target="_blank" className="btn-send text-uppercase">{language.reserve}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}