import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import { LanguageProvider } from './contexts/language';
import { LanguagePlayerProvider } from './contexts/language_player';
import { AuthProvider } from './contexts/auth';
import Routing from './routes';

function App() {
    return (
        <LanguageProvider>
            <LanguagePlayerProvider>
                <AuthProvider>
                    <Routing />
                </AuthProvider>
            </LanguagePlayerProvider>
        </LanguageProvider>
    );
}

export default App;