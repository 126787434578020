import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LuCalendarDays } from "react-icons/lu";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';

import thumb from '../../assets/images/thumb.jpg';
import thumb_en from '../../assets/images/thumb_en.jpg';

export default function Watch() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const [events, setEvents] = useState([]);


    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40 text-left form">
                    <div className="mt-0 mb-40">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="title_a text-center mb-60">
                                        4 de junho / June 4th / 4 de junio
                                    </h3>
                                </div>
                                <div className="col-md-4 mb-10">
                                    <Link to="/evento/1/4" className="link-event">
                                        <h4>Plenaria / Side Event A</h4>
                                        <img src={lang==='pt' ? thumb : thumb_en} className="bg-cover thumb" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mb-10">
                                    <Link to="/evento/2/4" className="link-event">
                                        <h4>Side Event B</h4>
                                        <img src={lang==='pt' ? thumb : thumb_en} className="bg-cover thumb" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mb-10">
                                    <Link to="/evento/3/4" className="link-event">
                                        <h4>Side Event C</h4>
                                        <img src={lang==='pt' ? thumb : thumb_en} className="bg-cover thumb" />
                                    </Link>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="title_a text-center mt-80 mb-60">
                                        5 de junho / June 5th / 5 de junio
                                    </h3>
                                </div>
                                <div className="col-md-4 mb-10">
                                    <Link to="/evento/1/5" className="link-event">
                                        <h4>Plenaria / Side Event A</h4>
                                        <img src={lang==='pt' ? thumb : thumb_en} className="bg-cover thumb" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mb-10">
                                    <Link to="/evento/2/5" className="link-event">
                                        <h4>Side Event B</h4>
                                        <img src={lang==='pt' ? thumb : thumb_en} className="bg-cover thumb" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mb-10">
                                    <Link to="/evento/3/5" className="link-event">
                                        <h4>Side Event C</h4>
                                        <img src={lang==='pt' ? thumb : thumb_en} className="bg-cover thumb" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
