import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useParams, useNavigate } from 'react-router-dom';
import { LuCalendarDays, LuPower, LuArrowLeft } from "react-icons/lu";
import dayjs from 'dayjs';

import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';

import LanguagePlayerContext from '../../contexts/language_player';

import br from '../../assets/images/br.png';
import en from '../../assets/images/en.png';
import es from '../../assets/images/sp.png';

export default function Event() {

    const { language_player, setLanguagePlayerStore } = useContext(LanguagePlayerContext);
    const lang_player = localStorage.getItem('language_player');

    function setPlayerLanguage(lang_player) {
        setLanguagePlayerStore(lang_player);
    }

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const { type, day } = useParams();

    const refVideo = useRef();

    const [event, setEvent] = useState([]);

    async function loadEvent(){

        setLoading(true);
        try{
            const response = await api.post(`/event`, {
                type, 
                day
            });
            setEvent(response.data.content.event);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        loadEvent();
    }, []);

    /* function getVideo(current_video, event, lang_player){
        const today = dayjs();
        const currentHour = today.hour();
        const currentMinute = today.minute();
        const targetHour = 14;
        const targetMinute = 30;
        const video_1 = lang_player==='pt' ? event.video : event.video_en
        const video_2 = lang_player==='pt' ? event.video_after : event.video_after_en
        if (currentHour > targetHour || (currentHour === targetHour && currentMinute >= targetMinute)) {
            if(current_video!==video_2){
                setCurrentVideo(video_2);
            }
        } else {
            if(current_video!==video_1){
                setCurrentVideo(video_1);
            }
        }
    } */

    function getVideo(type, lang_player){
        if(type=="1"){
            return lang_player==='pt' ? event.video_1 : lang_player==='en' ? event.video_1_en : event.video_1_es;
        }else if(type=="2"){
            return lang_player==='pt' ? event.video_2 : lang_player==='en' ? event.video_2_en : event.video_2_es;
        }else if(type=="3"){
            return lang_player==='pt' ? event.video_3 : lang_player==='en' ? event.video_3_en : event.video_3_es;
        }
    }

    return (
        <div className="page">
            <Header />
            {loading ?
            <Loader />:
            <div className="content-video">
                <div className="full-video">
                    <div className="header-title">
                        <div className="container event_top m-0">
                            <a className="header_back" onClick={() => navigate('/assistir')}>
                                <LuArrowLeft size={20} />
                                <div>{language_player.back}</div>
                            </a>
                            <div className="container_lang">
                                <a className={`item_lang ${lang_player==='pt' ? 'active' : '' }`} onClick={() => setPlayerLanguage('pt')}>
                                    <img src={br} />
                                    <div>{language_player.lang_pt}</div>
                                </a>
                                <a className={`item_lang ${lang_player==='en' ? 'active' : '' }`} onClick={() => setPlayerLanguage('en')}>
                                    <img src={en} />
                                    <div>{language_player.lang_en}</div>
                                </a>
                                <a className={`item_lang ${lang_player==='es' ? 'active' : '' }`} onClick={() => setPlayerLanguage('es')}>
                                    <img src={es} />
                                    <div>{language_player.lang_es}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="container-video-comments">
                        <div className="container-video">
                            <div ref={refVideo} className="embed-container">
                                {event!='' &&
                                <ReactPlayer
                                    url={`https://www.youtube.com/embed/${getVideo(type, lang_player)}?rel=0&showinfo=0&autoplay=1`}
                                    controls={true}
                                    playsinline={true}
                                    playing={true}
                                    frameBorder="0"
                                    allowFullScreen
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Footer noLogo />
        </div>
    );
}
