import React, { createContext, useState, useEffect } from 'react';

const LanguagePlayerContext = createContext();

const languages = {
    pt: {
        language: "pt",
        lang_pt: "Português",
        lang_en: "English",
        lang_es: "Español",
        back: "Voltar"
    },
    en: {
        language: "en",
        lang_pt: "Português",
        lang_en: "English",
        lang_es: "Español",
        back: "Back"
    },
    es: {
        language: "es",
        lang_pt: "Português",
        lang_en: "English",
        lang_es: "Español",
        back: "Volver"
    }
}

export const LanguagePlayerProvider = ({ children }) => {
    
    const [language_player, setLanguagePlayer] = useState({});

    async function loadStorage() {
        const storageLanguage = localStorage.getItem('language_player');
        if (!storageLanguage) {
            setLanguagePlayerStore('pt');
        }else{
            setLanguagePlayerStore(storageLanguage);
        }
    }

    async function setStorage(lang) {
        localStorage.setItem('language_player', lang);
    }

    function setLanguagePlayerStore(lang){
        if(lang==="pt" || lang==="PT"){
            setLanguagePlayer(languages.pt);
            setStorage("pt");
        }else if(lang==="en" || lang==="EN"){
            setLanguagePlayer(languages.en);
            setStorage("en");
        }else{
            setLanguagePlayer(languages.es);
            setStorage("es");
        }
    }

    useEffect(() => {
        loadStorage();
    }, [language_player]);

    return (
        <LanguagePlayerContext.Provider value={{ language_player, setLanguagePlayer, setLanguagePlayerStore }} >
            {children}
        </LanguagePlayerContext.Provider>
    )

}

export default LanguagePlayerContext;