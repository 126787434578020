import React, { useState, useEffect, useRef, useContext } from 'react';
import { LuCalendarDays } from "react-icons/lu";
import { FaRegSquare, FaSquareCheck, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function InscricaoImprensa() {

    const { signed } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');
    const [ended, setEnded] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [type, setType] = useState('presencial');
    const [events, setEvents] = useState('');
    
    const nameInput = useRef(null);
    const emailInput = useRef(null);
    const companyInput = useRef(null);
    const phoneInput = useRef(null);
    const eventsInput = useRef(null);

    function clearMessage(){
        setError(false);
        setErrorMessage('');
        setSuccess(false);
        setSuccessMessage('');
    }

    function clearState(){
        setName('');
        setEmail('');
        setCompany('');
        setPhone('');
        setType('presencial');
        setEvents('');
    }

    async function sendRegister(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setSuccessMessage("");

        if(name.trim() === ''){
            nameInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(email.trim() === ''){
            emailInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(company.trim() === ''){
            companyInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(phone.trim() === ''){
            phoneInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(events.trim() === ''){
            eventsInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(type === ''){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_type);
            return;
        }

        try{
            const response = await api.post(`/register`, {
                lang,
                name,
                email,
                company,
                phone,
                event: events,
                type_subscribe: type,
                type_user: 'press',
            });

            if(response.data.status==="success"){
                setLoading(false);
                setError(false);
                setErrorMessage('');
                setSuccess(true);
                setSuccessMessage(language.success);
                clearState();
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage(language.error_email);
                setSuccess(false);
                setSuccessMessage('');
            }
            
        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
            setSuccess(false);
            setSuccessMessage("");
        }
    }

    useEffect(() => {
        const today = dayjs();
        const formattedDate = today.format('YYYY-MM-DD HH:mm');
        const targetDate = dayjs('2024-06-01 00:00');
        const hasPassed = targetDate.isBefore(formattedDate);
        if(hasPassed){
            setEnded(true);
            setType('online');
        }
    }, []);

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40">
                    <form onSubmit={sendRegister} className={`form ${ loading ? 'loading' : '' }`}>
                        <h3 className="title_b mb-60 text-center">
                            {language.menu_imprensa}
                        </h3>
                        <div className="d-input mt-20">
                            <label>{language.name}: <span>*</span></label>
                            <input type="text" ref={nameInput} value={name} onChange={e => {setName(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.email}: <span>*</span></label>
                            <input type="email" ref={emailInput} value={email} onChange={e => {setEmail(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.company}: <span>*</span></label>
                            <input type="text" ref={companyInput} value={company} onChange={e => {setCompany(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.phone}: <span>*</span></label>
                            <input type="text" ref={phoneInput} value={phone} onChange={e => {setPhone(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.type}: <span>*</span></label>
                            <div className="d-check">
                                {!ended &&
                                <div className={`d-check-label ${type==='presencial' ? 'checked' : ''}`} onClick={() => {setType('presencial');clearMessage();}}>
                                    {type==='presencial' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.presencial}</span>
                                </div>}
                                <div className={`d-check-label ${type==='online' ? 'checked' : ''}`} onClick={() => {setType('online');clearMessage();}}>
                                    {type==='online' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.online}</span>
                                </div>
                            </div>
                            {ended &&
                            <div className="d-input mt-10 mb-10">
                                <em>{language.ended}</em>
                            </div>}
                        </div>
                        <div className="d-select">
                            <label>{language.days}: <span>*</span></label>
                            <select type="text" ref={eventsInput} value={events} onChange={e => {setEvents(e.target.value);clearMessage();}}>
                                <option value="">--</option>
                                <option value="1">{language.day_1}</option>
                                <option value="2">{language.day_2}</option>
                                <option value="3">{language.day_3}</option>
                            </select>
                        </div>
                        {success && <div className="success space mt-20 mb-20">{success_message}</div>}
                        {error && <div className="error space mt-20 mb-20">{error_message}</div>}
                        <div className="d-btn mt-30">
                            <button type="submit" className="btn-send">{language.send} {language.inscricao}</button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}
