import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import LanguageContext from '../../contexts/language';

export default function Week() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="page">
            <Header />
            <div className="full mt-80 mb-40 form">
                <h3 className="title_b">
                    {language.menu_week}
                </h3>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 m-auto">
                            {lang==='pt' ?
                            <div className="text">
                                <p>Seja bem-vindo a Semana da Filantropia!</p>
                                <p>No espírito da colaboração, temos o prazer de anunciar a realização de uma "Semana da Filantropia" intensiva no Rio de Janeiro, de 3 a 7 de junho. Aproveitando o momento do Climate Solutions Forum F20 (CSF), diversos parceiros irão realizar eventos complementares durante essa semana, com o objetivo de promover a troca de ideias, favorecer parcerias e amplificar as vozes do Sul Global. Aproveite ao máximo sua visita ao Rio de Janeiro participando da "Semana da Filantropia", onde terá a oportunidade de estabelecer contatos, aprender e contribuir com soluções práticas para questões climáticas.</p>
                                <p>Permaneça atento enquanto concluímos os detalhes e acrescentamos mais atividades à agenda. A Semana da Filantropia será uma experiência marcante, reunindo diversos atores em nosso objetivo comum de construir um futuro sustentável. Participe conosco nesta transformação de conceitos em prática e na geração de um impacto significativo em conjunto.</p>
                                <p>(Todos os eventos da Semana da Filantropia estão sujeitos a inscrição prévia e confirmação pelos organizadores. Alguns podem também ser sessões restritas e somente por convite. Todos os detalhes sobre as modalidades de participação serão compartilhados assim que estiverem disponíveis ao público.)</p>
                            </div>:
                            <div className="text">
                                <p>Welcome to Philanthropy Week!</p>
                                <p>In the spirit of collaboration, we are excited to announce a full "Philanthropy Week" from Monday, June 3 to Friday, June 7 in Rio de Janeiro. Leveraging the momentum of the F20 Climate Solutions Forum (CSF ), many of our partners will use this week to host additional events around the CSF to exchange ideas, forge alliances, and amplify the voices of the Global South. Maximize the value of your trip to Rio de Janeiro by participating in Philanthropy Week, and seize the opportunity to network, learn, and contribute to actionable climate solutions.</p>
                                <p>Stay tuned as we finalize details and add even more events to the lineup. Philanthropy Week promises to be a transformative journey, uniting diverse stakeholders in our collective pursuit of a sustainable future. Join us as we turn ideas into action and make a lasting impact together.</p>
                                <p>(Please note that all events of Philanthropy Week are subject to prior registration and confirmation by the individual hosts. Some might also be restricted and invitation-only sessions. All details on the participation modalities will be shared as soon as they are publicly available.)</p>
                            </div>}
                        </div>
                        <div className="col-md-10 m-auto">
                            <div className="table-responsive mt-10">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th width="8%">{lang==='pt' ? 'Data' : 'Date'}</th>
                                            <th width="15%">{lang==='pt' ? 'Horario' : 'Time'}</th>
                                            <th>{lang==='pt' ? 'Evento' : 'Event'}</th>
                                            <th width="12%">{lang==='pt' ? 'Local' : 'Location'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>03/jun</td>
                                            <td>14:00 - 17:00</td>
                                            <td>{lang==='pt' ? 'O envolvimento da filantropia com os processos do G20 - agora e no futuro' : 'Philanthropy\'s engagement with G20 processes - now and in the future'}</td>
                                            <td>OSF office</td>
                                        </tr>
                                        <tr>
                                            <td>03/jun</td>
                                            <td>18:00 - 20:00</td>
                                            <td>
                                                {lang==='pt' ? 'Reunião Informal de Filantropia para Adaptação Climática: Desafios e Experiências' : 'Philanthropy for Climate Adaptation Informal Meeting: Challenges and Experiences'}
                                                {lang==='pt' ?
                                                <p className="text-justify text-muted">Promover o diálogo e a troca de experiências internacionais e o aprendizado sobre como a filantropia pode atuar para promover a agenda de adaptação climática no Brasil e na Índia, com base nos eventos no Rio Grande do Sul e na colaboração climática da filantropia indiana.</p>:
                                                <p className="text-justify text-muted">To promote dialogue and exchange of international experiences and learning on how philanthropy can act to promote the climate adaptation agenda in Brazil and India, drawing from the events in Rio Grande do Sul and the climate collaboration of Indian philanthropy.</p>}
                                                Thelma Krug, Manavi Bhardwaj
                                            </td>
                                            <td>OSF office</td>
                                        </tr>
                                        <tr>
                                            <td>06/jun</td>
                                            <td>10:00 - 12:00</td>
                                            <td>{lang==='pt' ? 'Reunião de membros F20' : 'F20 Member Convening'}</td>
                                            <td>iCS office</td>
                                        </tr>
                                        <tr>
                                            <td>06/jun</td>
                                            <td>10:00 - 12:30</td>
                                            <td>
                                                {lang==='pt' ? 'Alavancagem do aprendizado: O Impacto Dinâmico da Filantropia na Educação Inclusiva' : 'Learning Leverage: Philanthropy\'s Dynamic Impact on Inclusive Education'}
                                                {lang==='pt' ?
                                                <p className="text-justify text-muted mb-0">A OCDE netFWD planeja organizar um evento temático sobre educação em parceria com a Fundação Getulio Vargas.<br />O evento temático discutirá especificamente como a filantropia pode se mobilizar para desenvolver e acelerar os compromissos do G20 do Brasil em relação à educação, com foco no clima. Ele explorará como alavancar dinâmicas existentes e construir novas dinâmicas para fornecer resultados concretos em termos sociais, econômicos e econômicos e ambientais, mantendo o foco na redução da pobreza e em não deixar ninguém para trás.</p>:
                                                <p className="text-justify text-muted mb-0">OECD netFWD plans to organise a thematic event on education in partnership with Fundação Getulio Vargas.<br />The thematic event will specifically discuss how philanthropy can step up to develop and accelerate Brazil's G20 commitments on education, with a focus on climate. It will explore how to leverage existing dynamics and build new ones to provide concrete deliverables on social, economic, and environment-linked Sustainable Development Goals, while retaining a tight focus on poverty reduction and leaving no one behind.</p>}
                                            </td>
                                            <td>FGV</td>
                                        </tr>
                                        <tr>
                                            <td>06/jun</td>
                                            <td>14:00 - 17:00</td>
                                            <td>
                                                {lang==='pt' ? 'Reunião Iniciativa de Filantropia Climática do Sul Global' : 'Global South Climate Philanthropy Initiative meeting'}
                                                {lang==='pt' ?
                                                <p className="text-justify text-muted mb-0">O conceito de um Retiro de Filantropia do Sul Global no Rio de Janeiro é uma progressão natural do bem-sucedido evento marco de Dubai, um esforço colaborativo da Fundación Avina, WINGS, GIFE, Latimpacto e Arab Foundations Forum (AFF).<br />Esse evento ressaltou a necessidade crítica de ação unificada e alinhamento estratégico entre as organizações filantrópicas do Sul Global. O sucesso do evento de Dubai evidenciou um forte desejo da comunidade filantrópica de se aprofundar na elaboração de uma estratégia compartilhada referente ao período do G20 à COP30.<br />Seu objetivo é desenvolver o documento de Mensagens-Chave que resulta das conversas iniciais realizadas em torno da COP28. Com base no impulso gerado pelo Fórum de Soluções Climáticas (4-5) de junho no Rio de Janeiro (Brasil), o retiro proposto visa facilitar uma exploração mais profunda com as principais partes interessadas. Esse encontro apresenta uma oportunidade única de participar de discussões abrangentes e aprofundar a estratégia e a narrativa comuns com o objetivo de responder à pergunta: “Qual é a história de sucesso que gostaríamos de ver na COP30 para a filantropia do Sul Global?"</p>:
                                                <p className="text-justify text-muted mb-0">The concept of a Global South Philanthropy Retreat in Rio de Janeiro is a natural progression from the successful Dubai milestone event, a collaborative effort by Fundación Avina, WINGS, GIFE, Latimpacto, and Arab Foundations Forum (AFF).<br />This event underscored the critical need for unified action and strategic alignment among philanthropic organizations in the Global South. The success of the Dubai event has evidenced a strong desire within the philanthropic community to delve deeper into crafting a shared strategy concerning the period from G20 to COP30.<br />It aims at building on the Key Messages document that results from these initial conversations held around COP28. Building on the momentum generated by the Climate Solutions Forum (4-5) of June in Rio de Janeiro (Brazil), the proposed retreat aims to facilitate a more profound exploration with key stakeholders. This gathering presents a unique opportunity to engage in comprehensive discussions and deepen the common strategy and narrative aiming at answering the question of "Which is the success story that we would like to see from COP30 for Global South philanthropy?"</p>}
                                            </td>
                                            <td>iCS office</td>
                                        </tr>
                                        <tr>
                                            <td>07/jun</td>
                                            <td>09:00 - 11:00</td>
                                            <td>
                                                {lang==='pt' ? 'Curvas de aprendizado sobre Litígio Estratégico: Aumentando os esforços em questões de clima, biodiversidade, alimentação, saúde e outros ODS' : 'Learning Curves on Strategic Litigation: Enhancing Efforts on Climate, Biodiversity, Food, Health, and Other SDG Issueses'}
                                                {lang==='pt' ?
                                                <p className="text-justify text-muted mb-0">Realizado de acordo com as regras da Chatham House, o evento oferecerá uma plataforma para o compartilhamento de experiências e perspectivas de várias organizações envolvidas em litígios estratégicos ou que os apoiam. Embora nos concentremos no litígio climático estratégico, nosso objetivo é obter insights para aumentar o impacto do litígio em outras áreas cruciais. Os principais pontos de discussão incluirão conhecimentos jurídicos, evidências e pesquisas, formação de alianças e narrativas eficazes.</p>:
                                                <p className="text-justify text-muted mb-0">Held under Chatham House rules, the event will provide a platform for sharing experiences and perspectives from various organizations involved in, or supporting, strategic litigation. While focusing on strategic climate litigation, we aim to derive insights to enhance the impact of litigation in other crucial areas. Key discussion points will include legal expertise, evidence and research, alliance-building, and effective narratives.</p>}
                                            </td>
                                            <td>iCS office</td>
                                        </tr>
                                        {/* <tr>
                                            <td>TBC</td>
                                            <td>TBC</td>
                                            <td>{lang==='pt' ? 'ICC + iCS' : 'ICC + iCS'}</td>
                                            <td>iCS office</td>
                                        </tr>
                                        <tr>
                                            <td>TBC</td>
                                            <td>TBC</td>
                                            <td>{lang==='pt' ? 'Litigation' : 'Litigation'}</td>
                                            <td>iCS office</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}