import React, { createContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

const languages = {
    pt: {
        language: "pt",
        language_pt: "Português",
        language_en: "Inglês",
        language_sigla: "PT-BR",
        language_active: "Português",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        menu_home: "Home",
        menu_news: "Painelistas",
        menu_hotel: "Local",
        menu_week: "Semana da Filantropia",
        menu_agenda: "Programação",
        menu_inscricao: "Registre-se",
        menu_imprensa: "Imprensa",
        menu_contato: "Fale Conosco",
        menu_events: "Eventos",
        menu_recommendation: "Recomendações",
        mais_info: "Mais informações e participação em breve.",
        reserve: "Reserve aqui",
        soon: "Em Breve.",
        agenda_btn: "AGENDA PRELIMINAR",
        desenvolvido: "Desenvolvido por",
        site_name: "Fórum de Soluções Climáticas",
        name: "Nome",
        email: "E-mail",
        password: "Crie sua Senha",
        just_password: "Senha",
        organization: "Organização",
        office: "Cargo",
        age: "Idade",
        race: "Raça",
        genre: "Gênero",
        phone: "Contato WhatsApp",
        disability: "Deficiência / Necessidade Específica",
        food: "Restrição Alimentar",
        type: "Tipo de Participação",
        company: "Veículo de comunicação",
        presencial: "Presencial",
        online: "Online",
        select: "Selecione os Eventos que deseja participar",
        send: "ENVIAR",
        update: "ATUALIZAR",
        pre_inscricao: "PRÉ-INSCRIÇÃO",
        inscricao: "INSCRIÇÃO",
        error_input: "Preencha todos os campos obrigatórios!",
        error_type: "Preencha o Tipo de Participação!",
        success: "Informações enviadas com sucesso!",
        error_email: "E-mail já está sendo usado por outra conta!",
        enter: "ACESSAR",
        error_login: "Erro ao fazer login!",
        error_input_email: "Preencha o campo E-mail!",
        error_input_senha: "Preencha o campo Senha!",
        error_incorrect: "E-mail ou senha incorretos!",
        login_title: "Faça Login para acessar o Evento",
        select_watch: "Selecione o Evento que deseja assistir",
        error_select: "Selecione pelo menos um Evento!",
        error_found: "Código não encontrado!",
        code: "Código",
        hello: "Olá",
        logout: "Sair",
        back: "Voltar",
        not_comment: "Nenhum comentário encontrado.",
        write_comment: "Escreva seu comentário...",
        send_comment: "ENVIAR COMENTÁRIO",
        menu_watch: "Assista Aqui",
        click_here: "Clique Aqui",
        ended_online: "Inscrição apenas disponível para participação On-line",
        ended: "Inscrições presenciais encerradas",
        yes: "Sim",
        no: "Não",
        food_info: "O cardárpio será todo vegano e vegetariano.",
        days: "Dias que participará",
        day_1: "Primeiro dia",
        day_2: "Segundo dia",
        day_3: "Os dois dias",
        select_day_1: "Dia 1",
        select_day_2: "Dia 2",
        event_side: "Tem interesse de participar dos side events?",
        comments: "Comentários",
        comments_info: "Use esse espaço para compartilhar expectativas, perguntas que gostaria que falássemos, área de expertise.",
        event_side_select: "Você está planejando participar do café da manhã do SPORTS20 (What can sport contribute to a transformed world? Global partnerships and the SPORTS20 Framerwork) no segundo dia, das 8:00 às 9:30?",
        contact_list: "Gostaria de ser adicionado a uma lista de contatos compartilhada com outros participantes?",
        event_materials: "Você teria interesse em receber materiais após o evento?",
        event_information: "Você tem interesse em receber informações sobre eventos futuros?",
        event_member: "Você representa uma organização membro da F20?",
        flower: "Qual é a sua flor favorita?",
        flower_1: "Rosa",
        flower_2: "Tulipa",
        flower_3: "Margarida",
        flower_4: "Lírio",
        flower_5: "Girassol",
        flower_6: "Orquídea",
        flower_7: "Petúnia",
        flower_8: "Flor de maracujá",
        flower_9: "Jasmim",
        flower_10: "Dália",
        accept: "Ao participar do evento você autoriza a organização a utilizar sua imagem, bem como seus áudios e vídeos que porventura forem captados.",
        alert_register: "A inscrição inicial é uma pré-inscrição e não garante a vaga presencial. A confirmação estará sujeita à lotação e a confirmação da modalidade aprovada da sua inscrição será enviada por e-mail.",
        capacity: "Evento gratuito sujeito à lotação",
        benefits: "Benefícios Rio Capital do G20",
    },
    en: {
        language: "en",
        language_pt: "Portuguese",
        language_en: "English",
        language_sigla: "EN",
        language_active: "English",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        menu_home: "Home",
        menu_news: "Panelists",
        menu_hotel: "Local",
        menu_week: "Philanthropy Week",
        menu_agenda: "Schedule",
        menu_inscricao: "Register",
        menu_imprensa: "Press",
        menu_contato: "Contact",
        menu_events: "Events",
        menu_recommendation: "Recommendations",
        mais_info: "More info and participation soon.",
        reserve: "Make a reservation",
        soon: "Coming Soon.",
        agenda_btn: "PRELIMINARY AGENDA",
        desenvolvido: "Developed by",
        site_name: "Climate Solutions Forum",
        name: "Name",
        email: "Email",
        password: "Create your Password",
        just_password: "Password",
        organization: "Organization",
        office: "Office",
        age: "Age",
        race: "Race",
        genre: "Gender",
        phone: "WhatsApp Contact",
        disability: "Disability / Specific Need",
        food: "Food Restriction",
        type: "Type of participation",
        company: "Communication media",
        presencial: "In person",
        online: "Online",
        select: "Select the events you want to participate in",
        send: "SEND",
        update: "UPDATE",
        pre_inscricao: "PRE-REGISTRATION",
        inscricao: "ENROLLMENT",
        error_input: "Fill in all mandatory fields!",
        error_type: "Fill in the type of participation!",
        success: "Information sent successfully!",
        error_email: "Email is already being used by another account!",
        enter: "ACCESS",
        error_login: "Error when logging in!",
        error_input_email: "Fill in the email field!",
        error_input_senha: "Fill in the password field!",
        error_incorrect: "Incorrect email or password!",
        login_title: "Login to access the Event",
        select_watch: "Select the event you want to watch",
        error_select: "Select at least one Event!",
        error_found: "Code not found!",
        code: "Code",
        hello: "Hello",
        logout: "Logout",
        back: "Back",
        not_comment: "No comments found.",
        write_comment: "Write your comment ...",
        send_comment: "SEND COMMENT",
        menu_watch: "Watch Here",
        click_here: "Click Here",
        ended_online: "Registration only available for Online participation",
        ended: "In-person registrations closed",
        yes: "Yes",
        no: "No",
        food_info: "The menu will be entirely vegan and vegetarian.",
        days: "Days of participation",
        day_1: "First day",
        day_2: "Second day",
        day_3: "Both days",
        select_day_1: "Day 1",
        select_day_2: "Day 2",
        event_side: "Are you interested in participating in the side events?",
        comments: "Comments",
        comments_info: "Use this space to share expectations, questions you would like us to address, area of expertise.",
        event_side_select: "Are you planning to attend the SPORTS20 breakfast session (What can sport contribute to a transformed world? Global partnerships and the SPORTS20 Framerwork) on Day 2 at 8:00-9:30?",
        contact_list: "Would you like to be added to a contact list shared with other attendees?",
        event_materials: "Would you be interested in receiving follow-up materials after the event?",
        event_information: "Are you interested in receiving information about future events?",
        event_member: "Do you represent an F20 member organisation?",
        flower: "What is your favourite flower?",
        flower_1: "Rose",
        flower_2: "Tulip",
        flower_3: "Daisy",
        flower_4: "Lily",
        flower_5: "Sunflower",
        flower_6: "Orchid",
        flower_7: "Petunia",
        flower_8: "Passion flower",
        flower_9: "Jasmine",
        flower_10: "Dahlia",
        accept: "By attending the event, you authorise the organisation to use your image, as well as any audio and video that may be recorded.",
        alert_register: "The initial registration is a pre-registration and does not guarantee an in-person spot. Confirmation will be subject to capacity, and the approval of your registration mode will be sent by email.",
        capacity: "Free event subject to full capacity",
        benefits: "Benefits of Rio G20 Capital",
    }
}

export const LanguageProvider = ({ children }) => {
    
    const [language, setLanguage] = useState({});

    async function loadStorage() {
        const storageLanguage = localStorage.getItem('language');
        if (!storageLanguage) {
            setLanguageStore('pt');
        }else{
            setLanguageStore(storageLanguage);
        }
    }

    async function setStorage(lang) {
        localStorage.setItem('language', lang);
    }

    function setLanguageStore(lang){
        if(lang==="pt" || lang==="PT"){
            setLanguage(languages.pt);
            setStorage("pt");
        }else{
            setLanguage(languages.en);
            setStorage("en");
        }
    }

    useEffect(() => {
        loadStorage();
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, setLanguageStore }} >
            {children}
        </LanguageContext.Provider>
    )

}

export default LanguageContext;