import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LuCalendarDays } from "react-icons/lu";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import Loader from '../../components/Loader';

export default function Agenda() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const [events, setEvents] = useState([]);

    async function loadEvents(){
        setLoading(true);
        try{
            const response = await api.post(`/events`);
            setEvents(response.data.content.events);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        loadEvents();
    }, []);

    function toggle(id){
        const wrapper = document.querySelector('#event_' + id);
        wrapper.classList.toggle('event-open');
    }

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40 text-center form">
                    <h3 className="title_b mb-10">
                        {language.menu_agenda}
                    </h3>
                    <p className="mb-60">{language.capacity}</p>
                    {loading ?
                    <Loader />:
                    <div className={`container mt-10`}>
                        <div className="row d-flex justify-content-around">
                            {events.map(item => (
                            <div key={`events-${item.id}`} className="col-md-6">
                                <div className="event-box">
                                    <div className="event-container">
                                        <div className="event-title">
                                            <LuCalendarDays />
                                            <span>{lang==='pt' ? item.title : item.title_en}</span>
                                        </div>
                                    </div>
                                    {item.event.map(event => (
                                    <div key={`event-${event.id}`} className={`event-content dont-click`}>
                                        <div className={`event-hour ${lang}`}>
                                            {event.time_start==event.time_end ?
                                            <span>{lang==='pt' ? event.time_start : event.time_start_en}</span>:
                                            <span>{lang==='pt' ? event.time_start : event.time_start_en} - {lang==='pt' ? event.time_end : event.time_end_en}</span>}
                                        </div>
                                        <div className={`event-content-wrapper ${(event.description || event.panel) ? 'has-open':''}`} onClick={() => (event.description || event.panel) && toggle(event.id)}>
                                            <div className="event-name">{lang==='pt' ? event.title : event.title_en}</div>
                                            {(event.description || event.panel) &&
                                            <div id={`event_${event.id}`} className={`event-close`}>
                                                {event.description &&<div className={`event-description ${event.panel ? 'event-border-end':''}`} dangerouslySetInnerHTML={{__html: lang==='pt' ? event.description : event.description_en}} />}
                                                {event.panel &&<div className="event-description text-panel" dangerouslySetInnerHTML={{__html: lang==='pt' ? event.panel : event.panel_en}} />}
                                            </div>}
                                        </div>
                                    </div>))}
                                </div>
                            </div>))}
                        </div>
                    </div>}
                </div>
            </div>
            <Footer />
        </div>
    );
}
