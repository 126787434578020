import React, { useState, useEffect, useRef, useContext } from 'react';
import { LuCalendarDays } from "react-icons/lu";
import { FaRegSquare, FaSquareCheck, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function Inscricao() {

    const { signed } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');
    const [ended, setEnded] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');
    const [office, setOffice] = useState('');
    const [age, setAge] = useState('');
    const [race, setRace] = useState('');
    const [genre, setGenre] = useState('');
    const [phone, setPhone] = useState('');
    const [disability, setDisability] = useState('no');
    const [food, setFood] = useState('no');
    const [disability_text, setDisabilityText] = useState('');
    const [food_text, setFoodText] = useState('');
    const [type, setType] = useState('presencial');
    const [events, setEvents] = useState('');
    const [event_side_1, setEventSide1] = useState('');
    const [event_side_2, setEventSide2] = useState('');
    const [event_side_2_opt, setEventSide2Opt] = useState('no');
    const [comments, setComments] = useState('');
    const [contact_list, setContactList] = useState('no');
    const [event_materials, setEventMaterials] = useState('no');
    const [event_information, setEventInformation] = useState('no');
    const [event_member, setEventMember] = useState('no');
    const [flower, setFlower] = useState('');
    const [accept, setAccept] = useState('no');
    
    const nameInput = useRef(null);
    const emailInput = useRef(null);
    const organizationInput = useRef(null);
    const officeInput = useRef(null);
    const ageInput = useRef(null);
    const phoneInput = useRef(null);
    const eventsInput = useRef(null);

    function handleAge(value) {
        const number = value.replace(/[^0-9]/g, '');
        setAge(number);
    }

    function handleAccept() {
        if(accept==='yes'){
            setAccept('no');
        }else{
            setAccept('yes');
        }
    }

    function clearMessage(){
        setError(false);
        setErrorMessage('');
        setSuccess(false);
        setSuccessMessage('');
    }

    function clearState(){
        setName('');
        setEmail('');
        setOrganization('');
        setOffice('');
        setAge('');
        setRace('');
        setGenre('');
        setPhone('');
        setDisability('no');
        setFood('no');
        setDisabilityText('');
        setFoodText('');
        setType('presencial');
        setEvents('');
        setEventSide1('');
        setEventSide2('');
        setComments('');
        setEventSide2Opt('');
        setContactList('no');
        setEventMaterials('no');
        setEventInformation('no');
        setEventMember('no');
        setFlower('');
        setAccept('no');
    }

    async function sendRegister(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setSuccessMessage("");

        if(name.trim() === ''){
            nameInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(email.trim() === ''){
            emailInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(organization.trim() === ''){
            organizationInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(office.trim() === ''){
            officeInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(age.trim() === ''){
            ageInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(phone.trim() === ''){
            phoneInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(events.trim() === ''){
            eventsInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(type === ''){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_type);
            return;
        }else if(accept === 'no'){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }

        try{
            const response = await api.post(`/register`, {
                lang,
                name,
                email,
                organization,
                office,
                age,
                race,
                genre,
                phone,
                disability,
                disability_text,
                food,
                food_text,
                comments,
                event: events,
                event_side_1,
                event_side_2,
                event_side_2_opt,
                contact_list,
                event_materials,
                event_information,
                event_member,
                flower,
                accept,
                type_subscribe: type,
            });

            if(response.data.status==="success"){
                setLoading(false);
                setError(false);
                setErrorMessage('');
                setSuccess(true);
                setSuccessMessage(language.success);
                clearState();
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage(language.error_email);
                setSuccess(false);
                setSuccessMessage('');
            }
            
        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
            setSuccess(false);
            setSuccessMessage("");
        }
    }

    useEffect(() => {
        const today = dayjs();
        const formattedDate = today.format('YYYY-MM-DD HH:mm');
        const targetDate = dayjs('2024-06-01 00:00');
        const hasPassed = targetDate.isBefore(formattedDate);
        if(hasPassed){
            setEnded(true);
            setType('online');
        }
    }, []);

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40">
                    <form onSubmit={sendRegister} className={`form ${ loading ? 'loading' : '' }`}>
                        <h3 className="title_b mb-60 text-center">
                            {language.menu_inscricao}
                        </h3>
                        <div className="d-input mt-20">
                            <label>{language.name}: <span>*</span></label>
                            <input type="text" ref={nameInput} value={name} onChange={e => {setName(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.email}: <span>*</span></label>
                            <input type="email" ref={emailInput} value={email} onChange={e => {setEmail(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.organization}: <span>*</span></label>
                            <input type="text" ref={organizationInput} value={organization} onChange={e => {setOrganization(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.office}: <span>*</span></label>
                            <input type="text" ref={officeInput} value={office} onChange={e => {setOffice(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.age}: <span>*</span></label>
                            <input type="tel" ref={ageInput} value={age} onChange={e => {handleAge(e.target.value);clearMessage();}} maxLength={2} />
                        </div>
                        <div className="d-input">
                            <label>{language.race}:</label>
                            <input type="text" value={race} onChange={e => {setRace(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.genre}:</label>
                            <input type="text" value={genre} onChange={e => {setGenre(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.disability}:</label>
                            <div className="d-check">
                                <div className={`d-check-label ${disability==='yes' ? 'checked' : ''}`} onClick={() => {setDisability('yes');clearMessage();}}>
                                    {disability==='yes' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.yes}</span>
                                </div>
                                <div className={`d-check-label ${disability==='no' ? 'checked' : ''}`} onClick={() => {setDisability('no');clearMessage();}}>
                                    {disability==='no' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.no}</span>
                                </div>
                            </div>
                            {disability==='yes' &&<input className="mt-10" type="text" value={disability_text} onChange={e => {setDisabilityText(e.target.value);clearMessage();}} />}
                        </div>
                        <div className="d-input">
                            <label>{language.food}:</label>
                            <div className="d-check">
                                <div className={`d-check-label ${food==='yes' ? 'checked' : ''}`} onClick={() => {setFood('yes');clearMessage();}}>
                                    {food==='yes' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.yes}</span>
                                </div>
                                <div className={`d-check-label ${food==='no' ? 'checked' : ''}`} onClick={() => {setFood('no');clearMessage();}}>
                                    {food==='no' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.no}</span>
                                </div>
                            </div>
                            {food==='yes' &&<input className="mt-10" type="text" value={food_text} onChange={e => {setFoodText(e.target.value);clearMessage();}} />}
                            <em className="mt-10">*{language.food_info}</em>
                        </div>
                        <div className="d-input">
                            <label>{language.phone}: <span>*</span></label>
                            <input type="text" ref={phoneInput} value={phone} onChange={e => {setPhone(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.type}: <span>*</span></label>
                            <div className="d-check">
                                {!ended &&
                                <div className={`d-check-label ${type==='presencial' ? 'checked' : ''}`} onClick={() => {setType('presencial');clearMessage();}}>
                                    {type==='presencial' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.presencial}</span>
                                </div>}
                                <div className={`d-check-label ${type==='online' ? 'checked' : ''}`} onClick={() => {setType('online');clearMessage();}}>
                                    {type==='online' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.online}</span>
                                </div>
                            </div>
                            {ended &&
                            <div className="d-input mt-10 mb-10">
                                <em>{language.ended}</em>
                            </div>}
                        </div>
                        <div className="d-select">
                            <label>{language.days}: <span>*</span></label>
                            <select type="text" ref={eventsInput} value={events} onChange={e => {setEvents(e.target.value);clearMessage();}}>
                                <option value="">--</option>
                                <option value="1">{language.day_1}</option>
                                <option value="2">{language.day_2}</option>
                                <option value="3">{language.day_3}</option>
                            </select>
                        </div>
                        {events!=='' &&
                        <div className="d-select">
                            <label>{language.event_side}:</label>
                            {(events==='1' || events==='3') &&
                            <select className="mb-2" value={event_side_1} onChange={e => {setEventSide1(e.target.value);clearMessage();}}>
                                <option value="">{language.select_day_1}</option>
                                <option value="1">Urban Air Quality – Breathe Cities Projects by Clean Air Fund</option>
                                <option value="2">Climate and Gender by OECD NetForward</option>
                                <option value="3">Local funds and Community Philantropy – the financial arm of our planet's Guardians by Allianza Fondos del Sur</option>
                            </select>}
                            {(events==='2' || events==='3') &&
                            <select className="mt-2" value={event_side_2} onChange={e => {setEventSide2(e.target.value);clearMessage();}}>
                                <option value="">{language.select_day_2}</option>
                                <option value="1">The role of Latin America for a just energy transition and the G20 Brazilian Presidency by FARN</option>
                                <option value="2">#PhilantropyForClimate: Tranformational approach to climate action by Wings</option>
                                <option value="3">Mobilizing capital to new/alternative sustainable economic models for the 3 Basins by CLUA</option>
                            </select>}
                        </div>}
                        {(events==='2' || events==='3') &&
                        <div className="d-input">
                            <label>{language.event_side_select}</label>
                            <div className="d-check">
                                <div className={`d-check-label ${event_side_2_opt==='yes' ? 'checked' : ''}`} onClick={() => {setEventSide2Opt('yes');clearMessage();}}>
                                    {event_side_2_opt==='yes' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.yes}</span>
                                </div>
                                <div className={`d-check-label ${event_side_2_opt==='no' ? 'checked' : ''}`} onClick={() => {setEventSide2Opt('no');clearMessage();}}>
                                    {event_side_2_opt==='no' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.no}</span>
                                </div>
                            </div>
                        </div>}
                        <div className="d-input">
                            <label>{language.comments}:</label>
                            <textarea rows="3" value={comments} onChange={e => {setComments(e.target.value);clearMessage();}} />
                            <em className="mt-10">*{language.comments_info}</em>
                        </div>
                        <div className="d-input">
                            <label>{language.contact_list}</label>
                            <div className="d-check">
                                <div className={`d-check-label ${contact_list==='yes' ? 'checked' : ''}`} onClick={() => {setContactList('yes');clearMessage();}}>
                                    {contact_list==='yes' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.yes}</span>
                                </div>
                                <div className={`d-check-label ${contact_list==='no' ? 'checked' : ''}`} onClick={() => {setContactList('no');clearMessage();}}>
                                    {contact_list==='no' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.no}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-input">
                            <label>{language.event_materials}</label>
                            <div className="d-check">
                                <div className={`d-check-label ${event_materials==='yes' ? 'checked' : ''}`} onClick={() => {setEventMaterials('yes');clearMessage();}}>
                                    {event_materials==='yes' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.yes}</span>
                                </div>
                                <div className={`d-check-label ${event_materials==='no' ? 'checked' : ''}`} onClick={() => {setEventMaterials('no');clearMessage();}}>
                                    {event_materials==='no' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.no}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-input">
                            <label>{language.event_information}</label>
                            <div className="d-check">
                                <div className={`d-check-label ${event_information==='yes' ? 'checked' : ''}`} onClick={() => {setEventInformation('yes');clearMessage();}}>
                                    {event_information==='yes' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.yes}</span>
                                </div>
                                <div className={`d-check-label ${event_information==='no' ? 'checked' : ''}`} onClick={() => {setEventInformation('no');clearMessage();}}>
                                    {event_information==='no' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.no}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-input">
                            <label>{language.event_member}</label>
                            <div className="d-check">
                                <div className={`d-check-label ${event_member==='yes' ? 'checked' : ''}`} onClick={() => {setEventMember('yes');clearMessage();}}>
                                    {event_member==='yes' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.yes}</span>
                                </div>
                                <div className={`d-check-label ${event_member==='no' ? 'checked' : ''}`} onClick={() => {setEventMember('no');clearMessage();}}>
                                    {event_member==='no' ?
                                    <FaRegCircleDot />:
                                    <FaRegCircle />}
                                    <span>{language.no}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-select">
                            <label>{language.flower} <span>*</span></label>
                            <select type="text" value={flower} onChange={e => {setFlower(e.target.value);clearMessage();}}>
                                <option value="">--</option>
                                <option value="1">{language.flower_1}</option>
                                <option value="2">{language.flower_2}</option>
                                <option value="3">{language.flower_3}</option>
                                <option value="4">{language.flower_4}</option>
                                <option value="5">{language.flower_5}</option>
                                <option value="6">{language.flower_6}</option>
                                <option value="7">{language.flower_7}</option>
                                <option value="8">{language.flower_8}</option>
                                <option value="9">{language.flower_9}</option>
                                <option value="10">{language.flower_10}</option>
                            </select>
                        </div>
                        <div className="d-radio">
                            <div className={`d-radio-label ${accept==='yes' ? 'checked' : ''}`} onClick={() => {handleAccept();clearMessage();}}>
                                <div className="d-radio-icon">
                                    {accept==='yes' ?
                                    <FaSquareCheck />:
                                    <FaRegSquare />}
                                </div>
                                <span>{language.accept} <span className="require">*</span></span>
                            </div>
                        </div>
                        {success && <div className="success space mt-20 mb-20">{success_message}</div>}
                        {error && <div className="error space mt-20 mb-20">{error_message}</div>}
                        <div className="d-btn mt-30">
                            <button type="submit" className="btn-send">{language.send} {type==='presencial' ? language.pre_inscricao : language.inscricao}</button>
                        </div>
                        <div className="d-input mt-30">
                            <em>{language.alert_register}</em>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}
