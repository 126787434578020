import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import logo from '../../assets/images/logo.png';

import LanguageContext from '../../contexts/language';

export default function Recommendation() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');


    return (
        <div className="page remove-bg">
            <Header />
            <div className="mt-40 mb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 m-auto">
                            <div className="text">
                                <h3 className="text-center mb-4">
                                    Accountability in Action:<br />
                                    Building bridges for North and South Partnerships
                                </h3>
                                <p className="text-center mb-4 pb-4">
                                    F20's Recommendations to the G20 under the Brazilian Presidency<br />
                                    May 2024
                                </p>
                                <p className="text-center mb-5 pb-4">
                                    <img src={logo} className="logo" />
                                </p>

                                <h4>Current Situation, May 2024</h4>
                                <p><strong>In 2024 Delhi, the G20 committed to leveraging its convening power to implement the 2030 Agenda and accelerate progress toward achieving the SDGs fully and effectively.<br />They must be held to this commitment.</strong></p>
                                <p>G20 economies are responsible for approximately 75% to 80% of global greenhouse gas emissions; they also hold 66% of the world's population and approximately 85% of the global gross domestic product (GDP). These factors mean the G20 has significant leverage to address climate change effectively. To avoid surpassing the critical 1.5°C temperature increase, it is imperative that the G20 governments prioritise and implement sustainable policies and innovations as promised.</p>
                                <p>However, the G20's economic dominance comes with disparities as the G20 nations also exhibit significant internal and external inequalities. These include income and wealth disparities within countries, differing levels of access to healthcare and education and varying capacities to address climate change and environmental degradation; these factors affect the ability of nations and communities to implement effective responses to global challenges. Therefore, the G20 nations must ensure the promotion of social equity and justice both within their own states and in solidarity with other less- developed countries.</p>
                                <p>As a group, the G20 represent diverse countries; each country has distinct potentials and starting points, per capita emissions and historical contributions to global emissions. The recent inclusion of the African Union as a permanent member of the G20 will enhance the G20's opportunities to bring about change. While the disparities between the member states must be recognized, and the urgency of environmental challenges fully acknowledged, the diversity among the members provides an ideal basis for enriching global dialogues and diverse perspectives to jointly take impactful action.</p>
                                <p>The F20 is a group of about 80 international and locally-anchored philanthropic organisations, and we call upon the G20 countries to actively implement their decisions and agreements made at the G7, G20 and the UNFCCC COPs. These meetings should first and foremost be about taking action to fulfil the 2030 Agenda with the SDGs, build resilient and just food systems and triple renewable energy by 2030.</p>

                                <h4>What is at stake and what is needed?</h4>
                                <p>The Intergovernmental Panel on Climate Change's increased warnings on escalating environmental and social impacts stress the immediate need for both global and local climate action. Last year's wildfires of unprecedented scale, record-breaking global temperatures and various extreme weather events across all continents underscore the relentless advance of planetary disturbances fuelled by human activities such as the burning of fossil fuels and large-scale ecosystem destruction. These incidents highlight the precarious balance of our climate system while emphasizing the need for sustained action to prevent long-term breaches of the 1.5°C limit set by the Paris Climate Agreement.</p>
                                <p>At the same time, we are facing an increase in poverty and hunger. The number of displaced people has hit a record high. We are confronted with escalating threats like biodiversity loss, armed conflicts, food insecurity, health crises and political polarization. Given the current geopolitical climate and the risk of escalating conflicts and wars, it is clear that we need much more cooperation, partnerships, mutual understanding and trust at all levels. The SDGs serve as a global roadmap in these divided times, and they address the critical issues of climate change and biodiversity loss that threaten livelihoods on this planet.</p>

                                <h4>Recent Steps Towards Building a Just World and a Sustainable Planet</h4>
                                <p>In 2023, the global community reached some milestones regarding the protection of our planet, notably recognising the importance of a circular economy for biodiversity. The UN 2023 Water Conference1 further emphasised the critical need to integrate water sustainability into the broader agenda of climate resilience and environmental protection. Additionally, we particularly welcome the following achievements in 2023:</p>
                                <ul>
                                    <li className="mb-3">Launched by Prime Minister Narendra Modi of India, the Lifestyle for Environment (LiFE) initiative encourages sustainable living practices among individuals and communities. This initiative aims to foster a global movement towards more environmentally friendly and sustainable habits, contributing to the broader goals of reducing emissions and promoting sustainability.</li>
                                    <li className="mb-3">The signs are clear for the <strong>end of the fossil fuel era</strong>. The endorsement of the Global Renewables and Energy Efficiency Pledge by over 130 countries at COP28 included a tripling of “the world's installed renewable energy generation capacity” as well as the “doubling of the global average annual rate of energy efficiency improvements”, both by 2030.3 This was well reflected in the G20 Leaders Declaration 2023.</li>
                                    <li className="mb-3"><strong>The UNFCCC Conference of the Parties (COP28) in Dubai, UAE</strong> agreed on the operationalisation of the loss and damage fund, promoted sustainable agriculture and addressed issues around food security and the effects of climate change on global health.</li>
                                </ul>
                                <p>However, we must go beyond merely recognizing these outcomes, hence we are pushing for accountability in taking action around these results.</p>
                            
                                <h4>Global Transformative Cooperation Needs in 2024</h4>
                                <p>In 2024, with Italy and Brazil hosting the G7 and G20 summits respectively, and Azerbaijan leading the Climate Conference (COP29), the focus should be on addressing questions related to global food justice, the SDGs and sustainable finance, especially with the expected agreement on the New Collective Quantified Goal on Climate Finance (NCQG) at COP29. These processes, along with the UN Summit of the Future set for September 2024, provide a crucial window to advance global environmental collaboration in an inclusive, intersectional and holistic manner. Furthermore, Colombia's hosting of the United Nations Biodiversity Conference in October 2024 is a critical moment that demands the exploration of explicit strategies for integrating biodiversity conservation into broader environmental and development agendas, ensuring a comprehensive and actionable global approach.</p>
                            
                                <p>Current scientific evidence concludes that keeping the global temperature increase below the 1.5°C benchmark is technically, economically and socially feasible.4 However, we recognize that there is no “One Size Fits All” strategy for all countries, and many grass-roots and local solutions in communities in all regions of the world strive to address these challenges in locally relevant ways.</p>

                                <p>To make inclusive climate action a reality we already know how to act. We must:</p>

                                <p><strong>
                                    1. Accelerate an inclusive and just transition towards renewable energy across all sectors.<br />
                                    2. Mobilize all resources and finance tools to align them with climate goals and effectively reduce inequalities beyond borders.<br />
                                    3. Boost ecosystem-based adaptation and resilience measures.<br />
                                    4. Build and scale sustainable and regenerative food systems.<br />
                                    5. Reform global governance structures towards equitable engagement.
                                </strong></p>
                                
                                <h4>F20 Recommendations for Action</h4>

                                <p>The following <strong>F20 Recommendations</strong> are intended to highlight the critical priorities for action at the ongoing G20 negotiations in 2024. They are complementary to the recommendations made by the official G20 Engagement Groups.</p>
                            
                                <p>
                                    <strong>1. Promoting Sustainable, Inclusive and Equitable Energy Transitions</strong><br />
                                    The transition to renewable energy systems must be both fast and fair. This requires political leadership. We urge the G20 to actively develop and promote accessible and affordable financing mechanisms. These should enable developing economies to enhance their renewable energy capacity, thus aligning with the global objectives to triple the adoption of renewable energy sources. Access to energy or the implementation of related mechanisms is as important as equitable and inclusive planning. F20 calls upon the G20 countries to adopt a justice-oriented approach, focusing on comprehensive p olicy f rameworks (including for critical minerals and biofuels), ensuring ecosystem and community protection. We recommend establishing programmes for workers in affected industries that provide re-skilling and financial support, integrating stringent social and environmental safeguards into all energy transition-related policies and embedding climate-focused education into national plans to equip future generations with the (green) skills they will need to thrive.
                                </p>

                                <p>
                                    <strong>2. Sustainable Industries: A Blueprint for Just Decarbonization</strong><br />
                                    Accelerating industrial decarbonization is crucial for meeting global climate targets, necessitating swift, equitable actions underpinned by decisive political leadership. This transformation depends on maximizing energy and resource efficiency, expanding access to renewable energy, significant investments in new electrical grids, sector coupling and storage solutions along with the initiation of change agents to drive institutional shifts. To augment a justice-focused industrial decarbonization policy for G20 nations, we emphasize a holistic approach that integrates carbon pricing to reflect environmental costs and support s affected communities, along with strict emission regulations to drive reductions. We call for the adoption of circular economy models that minimize waste and enhance resource and energy efficiency while fostering innovation and technology transfer to promote low-carbon technologies, especially in developing countries.
                                </p>

                                <p>
                                    <strong>3. Unlocking Access to Finance for Climate Action</strong><br />
                                    Access to finance remains a cornerstone for achieving the Paris Agreement objectives and the SDGs, and it involves cost of capital as well as new forms of investment funds limiting risks. Therefore, we call on G20 nations to enhance financial mechanisms that facilitate access to climate finance, especially for developing countries. This involves scaling up investments in green projects that focus on renewable energy and sustainable infrastructure. We advocate for the elimination of financial barriers by lowering capital costs through policy reforms and fostering the development of local financial markets. It is crucial to align investments from international financial institutions and the private sector with the goals of a low-carbon, climate-resilient future. This entails revising governmental investment portfolios to exclude fossil fuel investments as well as phasing out related subsidies and favouring funding for projects with clear, measurable climate benefits, while also leveraging public-private partnerships. We also call upon the G20 to simplify the process for developing countries to access climate finance, through streamlined application processes, capacity-building programmes to create bankable projects and the facilitation of direct access to international climate funds.
                                </p>

                                <p>
                                    <strong>4. Comprehensive Debt Relief and Tax Reform</strong><br />
                                    Debt relief measures, including innovative solutions like debt-for-climate swaps, will also enable the Global South to invest in green growth and climate resilience, fostering a fairer and more sustainable global economy. We call for a revision of the G20 Common Framework to ensure its smooth functioning. We also advocate for the inclusion of climate-related factors in the IMF's debt sustainability analysis and better solutions for channelling SDRs to the Global South. There should be more effort put into the development of adequate financing mechanisms for climate impacts and natural disasters. International and national tax systems that support the transition to a sustainable economy and contribute to a reduction in global inequalities are essential. We therefore suggest implementing tax reforms that incentivize green investments, disincentivize environmentally harmful practices and promote financial transparency and equity in resource allocation.
                                </p>

                                <p>
                                    <strong>5. Elevating Climate Adaptation in Policy and Finance</strong><br />
                                    Adaptation to climate change must be prioritized within G20 policies and the next round of more ambitious NDCs to improve overall resilience to the adverse effects of global warming. All new policies must mainstream adaptation strategies, informed by science and local knowledge. Strengthening the adaptive capacity of vulnerable regions, improving infrastructure resilience and promoting ecosystem-based solutions are crucial steps in ensuring a sustainable and climate-resilient future. We strongly encourage the G20 to foster an environment of learning and cooperation by sharing best practices, innovative technologies and successful adaptation strategies. G20 governments must join efforts with philanthropies and financial institutions to build capacity in Global South civil society groups, academia and subnational governments to develop local solutions for climate adaptation. This includes investing in scientific research and community-based adaptation initiatives.
                                </p>

                                <p>
                                    <strong>6. Integrating SDGs and Community Resilience in G20 Climate Actions</strong><br />
                                    As climate change exacerbates vulnerabilities and inequalities across the globe, we propose that G20 countries lead by example, harmonizing their climate policies with the broader objectives of social equity, economic stability and environmental protection. This entails crafting legislation and frameworks that not only mitigate climate change but also enhance the adaptive capacities of communities, particularly those most at risk. Therefore, we recommend a holistic strategy grounded in local realities, advocating for climate justice and overall well -being. By aligning resilience efforts with the SDGs, national leaders can address a wide range of interconnected challenges, including poverty, inequality, health and education. Moreover, focusing on the SDGs facilitates international collaboration and leverages global resources toward common objectives. We urge the G20 to engage with stakeholders at all levels, including local communities, civil society organizations and international partners to develop inclusive and effective approaches.
                                </p>

                                <p>
                                    <strong>7. Revitalizing Agriculture for Biodiversity, Climate Stability and Food Security</strong><br />
                                    The nexus between climate change, biodiversity loss and food insecurity requires urgent attention in which agriculture plays a pivotal role. We urge G20 nations to adopt agricultural policies that support sustainable practices, protect critical ecosystems such as peatlands and enhance the ecological benefits provided by agriculture. Effective policies must encourage farming techniques that minimize environmental impact, enhance soil health and reduce greenhouse gas emissions. This includes promoting crop rotation, organic farming and the reduced use of chemical fertilizers and pesticides. F20 further recommends mainstreaming agroecology into all relevant policies, while significantly enhancing policy coherence, enacting relevant national laws and ensuring that prices accurately reflect ecological and social impacts.
                                </p>

                                <p>
                                    <strong>8. Integrating Equity into Food Systems</strong><br />
                                    We recommend G20 nations promote sustainable food systems that prioritize food security and nutritional health. Policies should aim at transforming food systems to be more resilient, equitable and capable of providing healthy and sustainable diets for all, combating hunger and injustice in the process. Therefore, we call for programmes that support small-scale farmers and indigenous communities through access to markets, land rights and sustainable agricultural practices, ensuring they can contribute to and benefit from food systems. Implementing policies that address the root causes of food inequality can ensure fair pricing for producers and make nutritious foods accessible to all socio-economic groups.
                                </p>

                                <p>
                                    <strong>9. Make G20 Social a Baseline of Social Participation for Future G20 Presidencies</strong><br />
                                    Under the Brazilian presidency, the G20 Social initiative embodies a transformative approach to include non-governmental stakeholders at the heart of G20 activities and decision-making processes. We welcome Brazil's adoption of this inclusive model, and we urge the upcoming Presidencies to make the G20 Social a continuous structure to further democratize the G20 process. By fostering participation from civil society, academia, the private sector and indigenous communities, the G20 can ensure policies are more responsive, equitable and effective in addressing global challenges.
                                </p>

                                <p>
                                    <strong>10. Elevating the Voices of the Global South and Future Generations in Decision-making</strong><br />
                                    The need for a more inclusive and equitable global governance system has never been more pressing. We urge G20 nations to champion reforms that amplify the voices of the Global South and those of future generations in international decision- making forums, thereby strengthening the framework of multilateralism. This includes revising voting rights and representation in global financial institutions and ensuring that policies and resource allocations reflect the priorities of emerging societies. By addressing systemic imbalances and fostering a more democratic international order, the G20 can pave the way for a world that genuinely reflects the diversity and dynamism of its global community. Such reforms should ensure that all nations and generations have a say in shaping their future.
                                </p>

                                <h4>Philanthropy is Willing to Act and Support</h4>
                                <p>F20's global network of foundations considers itself part of the solution to climate change and the implementation of the SDGs. We recognize the crucial role that philanthropic investments play in achieving the SDGs and accelerating climate action. The recent commitments by philanthropic actors in initiatives like GEAPP, GAEA and PAA reflect a significant dedication to enhancing climate philanthropy.</p>

                                <p>We know how transformative philanthropy can be in driving climate action. Therefore, we are proud to work closely with philanthropists and social investors around the globe who are ambitious and courageous in their giving, reflecting the grave urgency of the environmental challenges we face. In leading by example, philanthropic actors reflect the recommendations outlined above in their actions, not only inspiring their peers to follow suit but also sending a clear message to governments about the acute need for immediate climate action and social transformation.</p>
                                <p>Particularly, we emphasize the importance of philanthropy's role in investing in 'catalytic capital'. Capital that is patient, risk-tolerant, concessional and flexible. As the battle against climate change demands a united effort, we are at an 'all-hands-on-deck' moment that calls for philanthropic actors to lead in climate action by investing in initiatives like climate solution incubators and accelerators – thereby supporting governments in their mammoth task of limiting temperature increase by 1.5°C. Such platforms play a crucial role in identifying and nurturing innovative ideas for a sustainable transition to a low-carbon economy.</p>
                                <p>Philanthropy is showing a clear will to foster the acceleration of promising solutions by adopting a systemic approach that includes collaboration with governments and embedding philanthropy within large-scale, transformative national projects.</p>
                                <p>Climate change is the biggest crisis we are facing, and we are the last generation of humans to act on it. Therefore, we need to ensure that all stakeholders are pulling in the same direction, in a concerted effort. Many philanthropic actors are willing to be part of the solution and support public as well as private climate action; they are ready to pick up the baton and show with concrete action that this community is ready to help wherever possible, despite sometimes limited resources.</p>


                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


